<template>
  <div v-loading="loadingData">
    <div class="alert alert-primary d-flex align-items-center p-5 mb-10">
    <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
      <inline-svg src="media/icons/duotune/general/gen048.svg" />
    </span>
      <div class="d-flex flex-column">
        <h4 class="mb-1 text-primary">Name : {{obj ? obj.title : ""}}</h4>
      </div>
    </div>
    <h4>Old Data</h4>
    <user-activity-log-comp :obj-data="logOld" :countries="countries" :phone-codes="phoneCodes" ></user-activity-log-comp>
    <h4>Changed Data</h4>
    <user-activity-log-comp :obj-data="logNew" :countries="countries" :phone-codes="phoneCodes" ></user-activity-log-comp>
  </div>
</template>

<script>
import {defineComponent, onMounted, ref ,watch} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {getIllustrationsPath} from "@/core/helpers/assets";
import {checkPermission} from "@/core/helpers/general";
import ApiAxiosService from "@/core/services/ApiAxiosService";
import {APIs} from "@/store/enums/ApiEnums";
import {handleError} from "@/core/helpers/response";
import {useRoute} from "vue-router/dist/vue-router";
import UserActivityLogComp from "@/components/admin/user/UserActivityLogComp";

export default defineComponent({
  name: "user-activity-log",
  components: {UserActivityLogComp},

  setup() {

    const logOld = ref({});
    const logNew = ref({});
    const obj   = ref(null);
    const countries = ref([]);
    const phoneCodes = ref([]);
    const documentStatuses = ref([]);
    const loadingData = ref(false);
    const route = useRoute();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Activity Log", ["Users"]);
    });


    const initData = async () => {
      let response = null;
      loadingData.value = true;
      try {
        response                = await ApiAxiosService.get(APIs.USER.activity_logs(route.params.id));
        logOld.value           = response.data.data.user_log_old;
        logNew.value           = response.data.data.user_log_new;
        countries.value         = response.data.data.countries;
        phoneCodes.value        = response.data.data.phone_codes;
        obj.value               = response.data.data.user;
      } catch (e) {
        handleError(e)
      }
      loadingData.value = false;

    }
    initData();
    watch(
        () => route.params.id, async newId => {
          if (newId) {
            initData();
          }

        }
    )
    return {
      getIllustrationsPath,
      loadingData,
      logOld,
      logNew,
      countries,
      phoneCodes,
      documentStatuses,
      obj
    };
  },

});
</script>
