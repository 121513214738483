<template>

  <el-form :class="'form-read-only'"
           :model="objData"
           ref="formRef">
    <div class="card mb-5 mb-xl-10">
      <div class="card-body pt-9 pb-0">

        <div class="row g-9 mb-7">
          <div v-if="objData.first_name" class="col-md-4 fv-row">
            <label class="required fs-6 fw-bold mb-2">First Name</label>
            <el-form-item prop="first_name">
              <el-input v-model="objData.first_name" placeholder="First Name"/>
            </el-form-item>
          </div>
          <div v-if="objData.last_name" class="col-md-4 fv-row">
            <label class="required fs-6 fw-bold mb-2">Last Name</label>
            <el-form-item prop="last_name">
              <el-input v-model="objData.last_name" placeholder="Last Name"/>
            </el-form-item>
          </div>
          <div v-if="objData.email" class="col-md-4 fv-row">
            <label class="required fs-6 fw-bold mb-2">Email</label>
            <el-form-item prop="email">
              <el-input v-model="objData.email" placeholder="Email"/>
            </el-form-item>
          </div>
        </div>
        <div class="row g-9 mb-7">
          <div v-if="objData.phone_code" class="col-md-1 fv-row phone-code">
            <label class="required fs-6 fw-bold mb-2">Code</label>
            <el-form-item prop="phone_code">
              <el-select v-model="objData.phone_code" placeholder="Select" style="width: 110px">
                <el-option v-for="phoneCode in phoneCodes" :label="phoneCode.phone_code"
                           :value="phoneCode.phone_code"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div v-if="objData.phone" class="col-md-3 fv-row">
            <label class="required fs-6 fw-bold mb-2">Phone</label>
            <el-form-item prop="phone">
              <el-input v-model.number="objData.phone" placeholder="Phone"></el-input>
            </el-form-item>
          </div>

          <div v-if="objData.city_id" class="col-md-4 fv-row">
            <label class="required fs-6 fw-bold mb-2">City</label>
            <el-form-item prop="city_id">
              <el-cascader class="full-width" v-model="objData.city_id" placeholder="Select Cities" :options="countries"
                           filterable clearable/>
            </el-form-item>
          </div>
          <div class="row g-9 mb-7">
            <image-comp v-if="objData.image" :element="objData.image" v-model="objData.image" col="col-md-3 fv-row"
                        title="Image"></image-comp>
            <div v-if="objData.is_active" class="col-md-4 fv-row">
              <label class="fs-6 fw-bold mb-2">Is Active</label>
              <el-form-item>
                <el-switch v-model="objData.is_active"/>
              </el-form-item>
            </div>
          </div>
        </div>
      </div>

    </div>
  </el-form>
</template>

<script>
import {defineComponent} from "vue";
import ImageComp from "@/components/admin/general/ImageComp";

export default defineComponent({
  name: "user-activity-log-comp",
  components: {ImageComp},
  props: ['objData', 'countries', 'phoneCodes'],
  setup(props) {

    return {};
  },

});
</script>


<style>
.selected-item {
  background-color: #bfffcc;
}
</style>